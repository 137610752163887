const words = [
  'baka',
  'senpai',
  'urusai',
  'kawaii',
  'omakase',
  'arigatou',
  'sensei',
  'kanojo',
  'gomen',
  'aho',
  'kuso',
  'moe',
  'yume',
  'daijoubu',
  'kurushii',
  'abunai',
  'bakemono',
  'dame',
  'ganbare',
  'itai',
  'ureshii',
  'ohayou',
  'sugoi',
  'yabai',
  'daisuki',
  'tomodachi',
  'uso',
  'sayorana',
  'tsundere',
  'megane',
  'nakama',
  'hentai',
  'itadakimasu',
  'imouto',
  'himitsu',
  'oyaji',
  'genki',
  'chigau',
  'demo',
  'hora hora',
  'omae',
  'kisama',
]

// notice me senpai

export default words
